#footer {
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  @media only screen and (min-width: 768px) {
    & > div {
      flex-direction: row;
    }
  }
}
